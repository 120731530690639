html,
body {
  padding: 0;
  margin: 0;
  font-family: 'ModerneLLWeb-Regular', serif, '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Helvetica Neue', Arial, 'Noto Sans',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

/* When lang is ko_KR change font family */
html[lang='ko-KR'],
html[lang='ko-KR'] body {
  font-family: 'ModerneLLWeb-Medium', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Helvetica Neue', Arial, 'Noto Sans', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
/* When lang is ja_JP change font family */
html[lang='ja-JP'],
html[lang='ja-JP'] body {
  font-family: 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック', '游ゴシック体', YuGothic, 'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ ゴシック',
    'MS Gothic', HiraKakuProN-W3, 'TakaoExゴシック', TakaoExGothic, 'MotoyaLCedar', 'Droid Sans Japanese', sans-serif;
}

/*
  En mi sistema el cambio de font-size inferior
  arregla el problema de las fuentes, antes de subir
  comprobemos que el resto lo ve bien

body,
.ant-modal,
.ant-modal-header,
.ant-modal-body,
.ant-btn,
.ant-form,
.ant-checkbox-wrapper,
.ant-modal-title {
  font-size: initial !important;
}

*/

#product_reviews span.bv-content-slider-value {
  border-radius: 50% !important;
}

@font-face {
  font-family: 'ModerneLLWeb-Light';
  src:
    url('/assets-new/fonts/ModerneLLWeb-Light.woff2') format('woff2'),
    url('/assets-new/fonts/ModerneLLWeb-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModerneLLWeb-Regular';
  src:
    url('/assets-new/fonts/ModerneLLWeb-Regular.woff2') format('woff2'),
    url('/assets-new/fonts/ModerneLLWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModerneLLWeb-Medium';
  src:
    url('/assets-new/fonts/ModerneLLWeb-Medium.woff2') format('woff2'),
    url('/assets-new/fonts/ModerneLLWeb-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModerneLight';
  src:
    url('/assets-new/fonts/ModerneLLWeb-Light.woff2') format('woff2'),
    url('/assets-new/fonts/ModerneLLWeb-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModerneRegular';
  src:
    url('/assets-new/fonts/ModerneLLWeb-Regular.woff2') format('woff2'),
    url('/assets-new/fonts/ModerneLLWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModerneMedium';
  src:
    url('/assets-new/fonts/ModerneLLWeb-Medium.woff2') format('woff2'),
    url('/assets-new/fonts/ModerneLLWeb-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CamperLab';
  src:
    url('/assets-new/fonts/folded-regular-webfont.woff2') format('woff2'),
    url('/assets-new/fonts/folded-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModerneFat';
  src:
    url('/assets-new/fonts/ModerneLLWeb-Fat.woff2') format('woff2'),
    url('/assets-new/fonts/ModerneLLWeb-Fat.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModerneBook';
  src:
    url('/assets-new/fonts/ModerneLLWeb-Book.woff2') format('woff2'),
    url('/assets-new/fonts/ModerneLLWeb-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModerneBold';
  src:
    url('/assets-new/fonts/ModerneLLWeb-Bold.woff2') format('woff2'),
    url('/assets-new/fonts/ModerneLLWeb-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ModerneBlack';
  src: url('/assets-new/fonts/ModerneLLWeb-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Camper unified Fonts */
/* Moderne */
@font-face {
  font-family: 'Moderne';
  src: url('/assets-new/fonts/ModerneLLWeb-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderne';
  src: url('/assets-new/fonts/ModerneLLWeb-Book.woff2') format('woff2');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderne';
  src: url('/assets-new/fonts/ModerneLLWeb-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderne';
  src: url('/assets-new/fonts/ModerneLLWeb-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderne';
  src: url('/assets-new/fonts/ModerneLLWeb-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderne';
  src: url('/assets-new/fonts/ModerneLLWeb-Black.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderne';
  src: url('/assets-new/fonts/ModerneLLWeb-Fat.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* End unified camper fonts */

/* NNORMAL Fonts */
/* Naming for font weights: https://support.invisionapp.com/hc/en-us/articles/115003217203-How-does-Inspect-determine-the-font-weight-displayed-in-my-prototype- */

@font-face {
  font-family: 'MinionPro-Regular';
  src: url('/assets-new/fonts/MinionPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Modelo';
  src:
    url('/assets-new/fonts/ModeloNNormal-Thin.woff') format('woff'),
    url('/assets-new/fonts/ModeloNNormal-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModeloThin';
  src:
    url('/assets-new/fonts/ModeloNNormal-Thin.woff') format('woff'),
    url('/assets-new/fonts/ModeloNNormal-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Modelo';
  src:
    url('/assets-new/fonts/ModeloNNormal-Light.woff') format('woff'),
    url('/assets-new/fonts/ModeloNNormal-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModeloLight';
  src:
    url('/assets-new/fonts/ModeloNNormal-Light.woff') format('woff'),
    url('/assets-new/fonts/ModeloNNormal-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Modelo';
  src:
    url('/assets-new/fonts/ModeloNNormal-Regular.woff') format('woff'),
    url('/assets-new/fonts/ModeloNNormal-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModeloRegular';
  src:
    url('/assets-new/fonts/ModeloNNormal-Regular.woff') format('woff'),
    url('/assets-new/fonts/ModeloNNormal-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Modelo';
  src:
    url('/assets-new/fonts/ModeloNNormal-Bold.woff') format('woff'),
    url('/assets-new/fonts/ModeloNNormal-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModeloBold';
  src:
    url('/assets-new/fonts/ModeloNNormal-Bold.woff') format('woff'),
    url('/assets-new/fonts/ModeloNNormal-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Modelo';
  src: url('/assets-new/fonts/Modelo-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ModeloBlack';
  src: url('/assets-new/fonts/Modelo-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* OLD Rhymes text */

@font-face {
  font-family: 'RyhmesText';
  src: url('/assets-new/fonts/RyhmesText-Light.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RyhmesTextThin';
  src: url('/assets-new/fonts/Rhymes-ThinText.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RyhmesText';
  src:
    url('/assets-new/fonts/RyhmesText-Light.woff2') format('woff2'),
    url('/assets-new/fonts/Modelo-Black.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RyhmesTextLight';
  src: url('/assets-new/fonts/RyhmesText-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RyhmesText';
  src: url('/assets-new/fonts/RyhmesText-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RyhmesTextRegular';
  src: url('/assets-new/fonts/RyhmesText-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RyhmesText';
  src: url('/assets-new/fonts/RyhmesText-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RyhmesTextSemiBold';
  src: url('/assets-new/fonts/RyhmesText-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RyhmesText';
  src: url('/assets-new/fonts/Rhymes-BoldText.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RyhmesTextBold';
  src: url('/assets-new/fonts/Rhymes-BoldText.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* New Rhymes Text font */
@font-face {
  font-family: 'Rhymes';
  src: url('/assets-new/fonts/Rhymes-ThinText.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rhymes';
  src: url('/assets-new/fonts/Rhymes-LightText.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rhymes';
  src: url('/assets-new/fonts/Rhymes-RegularText.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rhymes';
  src: url('/assets-new/fonts/Rhymes-SemiBoldText.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rhymes';
  src: url('/assets-new/fonts/Rhymes-BoldText.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ryhmes';
  src: url('/assets-new/fonts/Rhymes-ThinText.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ryhmes';
  src: url('/assets-new/fonts/Rhymes-LightText.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ryhmes';
  src: url('/assets-new/fonts/Rhymes-RegularText.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ryhmes';
  src: url('/assets-new/fonts/Rhymes-SemiBoldText.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ryhmes';
  src: url('/assets-new/fonts/Rhymes-BoldText.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bradford';
  src: url('/assets-new/fonts/BradfordLL-Light.oft') format('otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bradford';
  src: url('/assets-new/fonts/BradfordLL-LightItalic.oft') format('otf');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bradford';
  src: url('/assets-new/fonts/BradfordLL-Regular.oft') format('otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Japanese font */
@font-face {
  font-family: 'Noto Sans JP';
  src: url('/assets-new/fonts/ja/NotoSansJP-VariableFont_wght.woff2') format('woff2');
  font-weight: 100 1000;
}

/* Camperlab domain fonts */

@font-face {
  font-family: 'Diatype';
  src: url('/assets-new/fonts/camperlab/ABCDiatype-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Diatype';
  src: url('/assets-new/fonts/camperlab/ABCDiatype-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Diatype';
  src: url('/assets-new/fonts/camperlab/ABCDiatype-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Diatype';
  src: url('/assets-new/fonts/camperlab/ABCDiatype-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Diatype';
  src: url('/assets-new/fonts/camperlab/ABCDiatype-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Diatype';
  src: url('/assets-new/fonts/camperlab/ABCDiatype-RegularItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

:root {
  --color-white: #ffffff;
  --color-primary: #333;
  --color-black: #000;
  --color-text-secondary: #999;
  --color-grey: #e0e0e0;
  --color-grey-light: #dfdfdf;
  --color-grey-lightest: #e6e6e6;
  --color-grey-light-two: #f6f6f6;
  --color-error: #da291c;
  --color-disabled-primary: #919191;
  --color-divider: #d9d9d9;
  --color-success: #07b323;
  --color-warn: #fc8106;
  --color-tag-checked: #e0e0e0;
  --color-border-grey: #d1cfd2;
  --background: #ffffff;
  --color-camperlab: #f1efe5;
  --color-recycled: #edf1c6;
  --color-subgrid: #f6f6f6;
  --color-green-nnormal: #bdfda9;
  --justify-padding: 10px;
  --color-border-panel: #929292;
  --color-input-border: #cac6c6;
  --color-brand: #ed1a2c;
  --color-afterpay: #000000;
  --xxl-size: 1.5em;
  --xl-size: 1.4em;
  --large-size: 1.125em;
  --heading-1-size: 1em;
  --regular-size: 1em;
  --title-size: 0.875em;
  --description-size: 0.75em;
  --medium-size: 0.6875em;
  --small-size: 0.625em;
  --xsmall-size: 0.5em;
  --bold-font: ModerneLLWeb-Medium, sans-serif;
  --font-primary: 'ModerneLLWeb-Regular', sans-serif;
  --font-moderne: 'Moderne', sans-serif;
  --font-secondary: var(--bold-font);
  --font-camperlab: 'Bradford', serif;
  --font-moderne-fat: 'ModerneFat', sans-serif;
  --font-jp: 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック', '游ゴシック体', YuGothic, 'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ ゴシック',
    'MS Gothic', HiraKakuProN-W3, 'TakaoExゴシック', TakaoExGothic, 'MotoyaLCedar', 'Droid Sans Japanese', sans-serif;
  --voucher-subscribe-text: #b6d7a8;
  --voucher-cta: #000;
  --font-diatype: 'Diatype', sans-serif;
}

.green {
  color: var(--color-success) !important;
  /* que se choca con otros selectores */
}

#dimContainer {
  height: 87vh;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  #dimContainer {
    height: 80vh;
  }
}

#encryptedCardNumber {
  caret-color: var(--color-black);
}

a.boldLink {
  color: var(--color-black);
  font-family: var(--font-primary);
  text-decoration: none;
}

a.boldLink:hover {
  color: #919191;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.escondido {
  display: none !important;
  height: 0;
  width: 0;
}

.transparent {
  opacity: 0;
}

.transparentBackground {
  background-color: transparent !important;
}

.lowercase {
  text-transform: lowercase;
}

.zIndexFront {
  z-index: 200;
}

.success {
  color: var(--color-success);
}

@media (max-width: 767.5px) {
  .filterOpen {
    overflow: hidden;
  }
}

/* .intersecterLoader::before {
  content: "Loading...";
} */
.intersecterLoader {
  height: 10vh;
  width: 100%;
  font-size: 20px;
  text-align: center;
}

.customRadio .ant-radio-inner {
  border-radius: 0%;
}

.customRadio .ant-radio-inner::after {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 14px;
  height: 14px;
  border-radius: 0px;
}

.errorTitle {
  font-size: 38px;
  color: var(--color-error);
  border-bottom: 1px solid var(--color-grey);
}

.error {
  color: var(--color-error);
  text-align: center;
}

.errorMessage {
  background-color: var(--color-grey);
  padding: 2%;
}

.test-grid-a {
  display: grid;
  grid-column: 1;
  grid-row: 1;
}

.test-grid-b {
  display: none;
  grid-column: 1;
  grid-row: 1;
}

.customForm .ant-form-item:not(.ant-form-item-has-error) .ant-form-item-explain {
  background-color: var(--color-tag-checked);
  color: var(--color-black);
  font-size: var(--description-size);
  padding: 4px 8px;
  position: absolute;
  top: 55px;
  width: 100%;
  z-index: 3;
}

.customForm .ant-form-item.ant-form-item-has-warning .ant-form-item-explain {
  background-color: #fff;
  border: 1px solid #faad14;
}

.customForm .ant-form-item-has-error .ant-form-item-explain {
  background-color: var(--color-white) !important;
  /* a que el :not me gana? pues toma !important */
  border: 1px solid var(--color-error);
  color: var(--color-error);
  font-size: var(--description-size);
  padding: 4px 8px;
  position: absolute;
  top: 55px;
  width: 100%;
  z-index: 3;
}

.customForm .ant-form-item-children-icon > span {
  display: none;
}

.customForm .ant-form-item:not(.ant-form-item-has-error) .ant-form-item-children-icon::after {
  position: absolute;
  display: table;
  border: 2px solid var(--color-success);
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
  width: 5.71428571px;
  height: 9.14285714px;
  top: 50%;
  left: 22%;
}

.customForm .ant-form-item-has-error .ant-form-item-children-icon > span {
  display: block !important;
}

.customForm .ant-form-item-has-error .ant-form-item-children-icon::after {
  display: none !important;
}

.customForm .ant-input-number {
  width: 100%;
  height: 56px;
}

.customForm .ant-input-number-input {
  height: 56px;
}

.customForm .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-white);
}

.customForm .ant-checkbox-checked .ant-checkbox-inner::after {
  border-top-color: var(--color-black);
  border-bottom-color: var(--color-black);
  border-right-color: var(--color-black);
}

.customForm .ant-input-affix-wrapper {
  height: 56px;
}

.customForm .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}

.customForm .ant-input-affix-wrapper > input.ant-input {
  padding-top: 6px;
}

.customForm .ant-input-affix-wrapper + label {
  z-index: 1;
}

.customFormTransparent .ant-form-item-has-error .ant-form-item-explain {
  background-color: transparent !important;
  /* a que el :not me gana? pues toma !important */
  border: 1px solid transparent;
  color: var(--color-error);
  font-size: var(--description-size);
  padding: 4px 8px;
  position: absolute;
  top: 55px;
  width: 100%;
  z-index: 3;
}

.privacyPolicy > div > div.ant-form-item-explain-error {
  top: 35px !important;
}

@media (max-width: 506px) {
  .privacyPolicy > div > div.ant-form-item-explain-error {
    top: 58px !important;
  }
}

@media (min-width: 507px) and (max-width: 767px) {
  .privacyPolicy > div > div.ant-form-item-explain-error {
    top: 35px !important;
  }
}

@media (min-width: 768px) and (max-width: 832px) {
  .privacyPolicy > div > div.ant-form-item-explain-error {
    top: 45px !important;
  }
}

.customFormTransparent .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
}

.ant-rate-star-first,
.ant-rate-star-second {
  color: rgb(223, 223, 223) !important;
}

.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit !important;
}

.ant-rate-star > div:hover,
.ant-rate-star > div:focus-visible {
  transform: scale(1) !important;
}

.ant-modal {
  top: 0px !important;
}

.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grecaptcha-badge {
  visibility: hidden;
}

.ui-checkbox {
  display: none;
}

.ant-modal-wrap {
  align-items: initial;
}

.ant-modal-wrap > .ant-modal {
  height: fit-content;
  margin: auto;
  padding-bottom: 0px;
}

.ant-modal-confirm-content a,
.ant-modal-confirm-content a:hover {
  color: var(--color-black);
  text-decoration: underline;
  font-weight: bold;
}

.subclaimcta {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, rgba(190, 190, 190, 0.2)),
    color-stop(37%, rgba(129, 129, 129, 0.24)),
    color-stop(63%, rgba(190, 190, 190, 0.2))
  );
  background: -webkit-linear-gradient(left, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background: -moz-linear-gradient(left, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
  color: transparent;
}

.tooltip .ant-tooltip-content {
  display: flex;
  justify-content: flex-end;
}

.tooltip .ant-tooltip-inner {
  background-color: #dfdfdf;
  color: black;
  min-height: 17px;
  font-size: var(--description-size);
  width: 60vw;
}

.tooltip .ant-tooltip-arrow-content {
  background-color: #dfdfdf;
  width: 10px;
  height: 10px;
}

/* Customize accordion collapse for nnormal */

@media print {
  body * {
    visibility: hidden;
  }

  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #no-print,
  #no-print * {
    visibility: hidden;
    margin: 0 !important;
  }

  #no-print-hide,
  #no-print-hide * {
    visibility: hidden;
    height: 0;
    display: none;
  }

  #print-no-margin {
    margin-top: -2em !important;
    margin-bottom: 2em !important;
  }

  #section-to-print {
    color: inherit;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    position: fixed;
    top: 3rem;
    background-color: theme('backgroundColor.clear');
  }
}
/* NotNormal global styles */

body[data-domain='nnormal'] {
  --font-primary: 'Rhymes', serif;
  --font-secondary: 'Modelo', sans-serif;
  --color-nnormal-gray: #555555;
  --bold-font: var(--font-primary), sans-serif;
  --font-moderne: var(--font-primary), sans-serif;
  --font-camperlab: var(--font-primary), serif;
  --font-moderne-fat: var(--font-primary), sans-serif;

  font-family: var(--font-primary);
}

/* Camperlab styles */
body[data-domain='camperlab'] {
  --bold-font: var(--font-diatype);
  --font-primary: var(--font-diatype);
  --font-moderne: var(--font-diatype);
  --font-secondary: var(--font-diatype);
  --font-camperlab: var(--font-diatype);
  --font-moderne-fat: var(--font-diatype);
  --background: theme('backgroundColor.dim');

  font-family: var(--font-primary);
  background-color: var(--background);
  font-weight: 700;
}

body[data-domain='camperlab'] h1,
body[data-domain='camperlab'] h2,
body[data-domain='camperlab'] h3,
body[data-domain='camperlab'] h4,
body[data-domain='camperlab'] h5,
body[data-domain='camperlab'] h6 {
  font-weight: 700;
}
html[lang='ja-JP'] body {
  --bold-font: var(--font-jp);
  --font-primary: var(--font-jp);
  --font-moderne: var(--font-jp);
  --font-secondary: var(--font-jp);
  --font-camperlab: var(--font-jp);
  --font-moderne-fat: var(--font-jp);

  font-family: var(--font-jp);
}

html[lang='ja-JP'] body:not([data-domain='camperlab']) {
  --font-jp: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック', '游ゴシック体', YuGothic, 'Yu Gothic', 'メイリオ', Meiryo,
    'ＭＳ ゴシック', 'MS Gothic', HiraKakuProN-W3, 'TakaoExゴシック', TakaoExGothic, 'MotoyaLCedar', 'Droid Sans Japanese', sans-serif;
}

body[data-domain='camper'] .map > div > div.leaflet-pane.leaflet-tile-pane {
  filter: grayscale(1);
}

#minibag-continue-shopping {
  display: none;
}

/* body[data-domain='camperlab'] .tnm__modal #lab-cart-icon {
  display: none;
}

body[data-domain='camperlab'] .tnm__modal #lab-cart-text,
body[data-domain='camperlab'] .tnm__modal #lab-cart-count {
  display: flex;
  font-size: 1.313rem;
}

body[data-domain='camperlab'] .tnm__modal #my-account-widget-label {
  font-size: 1.313rem;
  text-align: left;
} */

@media screen and (max-width: 1439.5px) {
  body:not([data-domain='camperlab']) .tnm__modal #my-account-widget-label {
    position: relative;
    left: 30px;
    top: 4px;
    font-size: 0.75em;
    display: flex;
  }

  body[data-domain='nnormal'] .tnm__modal #my-account-widget-label {
    font-size: 1em;
  }
}

@media (max-width: 1199px) {
  body[data-domain='nnormal'] .tnm--mobile div:not(.tnm__modal) .globalEOrdersIcon {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  body[data-domain='camperlab'] .tnm--bag {
    position: absolute;
    left: 50px;
    padding-top: 3px;
  }

  body[data-domain='camper'] .tnm__modal .account-icon {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  body[data-domain='camperlab'] .tnm--bag {
    padding: 10px;
  }
  body[data-domain='camperlab'] .tnm--bag #lab-cart-count {
    @apply text-sm;
  }
}

@media screen and (min-width: 768px) {
  .tnm--account {
    visibility: hidden;
  }
}

body[data-domain='camperlab'] #lab-cart-text {
  @apply sr-only;
}

body[data-domain='camperlab'] .tnm__modal #lab-cart-text {
  @apply not-sr-only;
}

@media screen and (min-width: 1024px) {
  body[data-domain='camperlab'] #lab-cart-text {
    @apply not-sr-only;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition:
    background-color 5000s ease-in-out 0s,
    color 5000s ease-in-out 0s;
  color: inherit;
}

body[data-domain='camperlab'] .pre-sale-modal .ant-modal-body {
  padding: 8px 10px;
}
body[data-domain='camperlab'] .ant-modal-content {
  box-shadow: none;
}
